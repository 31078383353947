<template>
  <div class="maxBox">
    <div class="content">
      <h1 class="bounceInDown animated">友动(北京)体育发展有限公司</h1>
      <h2 class="bounceInDown animated">YOUDONG Physical Education</h2>
      <div class="bounceInDown animated">
        友动无人台球聚焦于24H自助台球厅线上线下相结合,软硬件匹配,为商户提供远程便捷的台球厅管理,为用户提供多种模式,简单便捷的打球体验。为无人台球,自助台球行业带来新的解决方案。推动台球行业蓬勃发展。
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.maxBox {
  background: url("../assets/img/about_bg3.jpg") center center no-repeat;
  background-size: cover;
  height: 100vh;
  color: rgb(245, 238, 238);
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  z-index: 0;
  text-align: center;
  h1 {
    font-size: 100px;
    text-shadow: 5px 5px 5px black, 0px 0px 2px black;
    margin: auto;
    font-family: "Microsoft YaHei UI";
  }
  h2 {
    font-size: 44px;
    text-shadow: 5px 5px 5px black, 0px 0px 2px black;
    font-family: "Microsoft YaHei UI";
    margin: 40px 0 50px;
  }
  div {
    text-align: left;
    font-family: "Microsoft YaHei UI";
    // text-shadow: 5px 5px 5px black, 0px 0px 2px black;
    font-size: 18px;
    line-height: 2em;
    text-indent: 2em;
  }
}
</style>
